import React, { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import type { Store } from 'redux';

import { redirectTo } from 'actions/redirect';
import { buildAuthenticationRedirectUrl } from 'utils/redirect';
import SiteAwareMetadata from 'components/SiteAwareMetadata';
import FederatedLogin from 'components/account/FederatedLogin';
import marketplace from 'cfg/marketplace.json';
import useMartyContext from 'hooks/useMartyContext';
import type { AppState } from 'types/app';

import css from 'styles/containers/account/federatedLoginPage.scss';

const ZAW_PARTNERS = ['amazon'];

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<{}, {}>;

// The `tokenExchangeRedirect` query param allows iOS/android apps to create a wrapper around this page via webview, and ensure their login request
// gets forwarded to ZAP to hit the correct v3/v4 login mafia endpoint. This will be included in the requesting url.
// The enableProviders query param allows the apps to determine which set of login providers are shown via comma seperated list
// ?enableProviders=google,facebook,apple
export const FederatedLoginPage = ({ content, location, redirectTo }: Props) => {
  const { search } = location;
  const searchParams = new URLSearchParams(search);

  const tokenExchangeRedirect = searchParams.get('tokenExchangeRedirect') ?? undefined;
  const returnTo = searchParams.get('openid.return_to') ?? '';
  const enableProviders = searchParams.get('enableProviders') ?? undefined;

  const {
    marketplace: { hasFederatedLogin }
  } = useMartyContext();

  let isZAW = false;
  let zawPartner = '';
  if (returnTo) {
    const zawIndexOfEndPath = returnTo.indexOf('zaw');
    const zawEndPath = returnTo.substring(zawIndexOfEndPath);
    isZAW = !!zawIndexOfEndPath;
    const partner = ZAW_PARTNERS[0];
    if (partner && zawEndPath.indexOf(partner) > -1) {
      zawPartner = 'amazon';
    }
  }

  useEffect(() => {
    if (!(hasFederatedLogin || tokenExchangeRedirect === 'true')) {
      const authUrl = buildAuthenticationRedirectUrl(returnTo);
      redirectTo(authUrl);
    }
  }, [hasFederatedLogin, redirectTo, returnTo, tokenExchangeRedirect]);

  const {
    Global: {
      slotData: { federatedLoginModalCopy } = {
        federatedLoginModalCopy: undefined
      }
    } = {}
  } = content;

  return (
    <SiteAwareMetadata>
      <div className={css.page}>
        <FederatedLogin
          copy={federatedLoginModalCopy}
          enableProviders={enableProviders}
          tokenExchangeRedirect={tokenExchangeRedirect}
          returnTo={returnTo}
          isZAW={isZAW}
          zawPartner={zawPartner}
        />
      </div>
    </SiteAwareMetadata>
  );
};

FederatedLoginPage.beforeFetchDataOnServer = (store: Store, location: any) => {
  const {
    query: { tokenExchangeRedirect, ['openid.return_to']: returnTo } = {
      tokenExchangeRedirect: undefined,
      ['openid.return_to']: undefined
    }
  } = location;
  if (!(marketplace.hasFederatedLogin || tokenExchangeRedirect === 'true')) {
    const authUrl = buildAuthenticationRedirectUrl(returnTo);
    store.dispatch(redirectTo(authUrl));
  }
};

const mapStateToProps = (state: AppState) => ({
  content: state.headerFooter.content
});

const mapDispatchToProps = {
  redirectTo
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FederatedLoginPage);
